import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from '../services/toast.service';

@Component({
    selector: 'app-notification-card',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './notification-card.component.html',
    styles: [],
})
export class NotificationCardComponent implements OnInit {
    @Input() notificationInfo: any;
    @Output() selectedChanged = new EventEmitter<boolean>();
    selected = false;
    isMobileWidth: boolean | undefined;
    positionTop: any;

    constructor(public toastService: ToastService) {}

    remove(index: number) {
        this.toastService.remove(index);
    }

    ngOnInit(): void {
        this.getWidthOfScreenSize();
    }

    ngAfterViewInit() {
        this.getWidthOfScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getWidthOfScreenSize() {
        let priceCard: any;
        this.toastService.toasts.forEach((toast) => {
            priceCard = toast.priceCard;
        });
        const screenWidth = window.innerWidth;
        if (screenWidth < 1280) {
            this.isMobileWidth = true;
        } else {
            this.isMobileWidth = false;
        }

        if (this.isMobileWidth) {
            this.positionTop = '60px';
        }
        if (!this.isMobileWidth && priceCard) {
            this.positionTop = '310px';
        }
        if (!this.isMobileWidth && !priceCard) {
            this.positionTop = '80px';
        }
    }

    selectNotification() {
        this.selectedChanged.emit(!this.selected);
    }
}
