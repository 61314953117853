<ng-container>
    <div class="widgetCenter">
        <div class="d-grid align-content-center route-container">
            <div class="route-content justify-content-center">
                <div class="route-page-container justify-content-center">
                    <div class="content-wrapper animate-content">
                        <div class="content d-flex align-items-center flex-md-row flex-column justify-content-center">
                            <img class="image col-md-6 col-12" [src]="'../../../../assets/images/login-woman.svg'"
                                [alt]="'login-image'" />
                            <div
                                class="widget-container col-md-6 col-12 d-flex align-items-center justify-content-center flex-column">
                                <app-okta-widget></app-okta-widget>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>