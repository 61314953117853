<div class="vertical-center">
    <!-- Desktop View -->
    <div class="d-none d-md-flex justify-content-center desktop-view">
        <div class="d-flex flex-row justify-content-center psic-gap-6">
            <!-- Left Section -->
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex flex-column justify-content-center header-section">
                    <img
                        src="../../../../../assets/images/system-failure.svg"
                        class="error-page-image w-100"
                        alt="page.getField('image')?.icon_path"
                    />
                </div>
            </div>

            <!-- Right Section -->
            <div class="d-flex flex-column justify-content-center content-section psic-gap-8">
                <div class="d-flex flex-column psic-gap-1">
                    <h1>Oops! It's not you, it's us. We are looking into it</h1>
                    <p>
                        You can also go back and try again or call us at <a href="#"><b>800-303-5000</b></a
                        >.
                    </p>
                </div>

                <ng-container>
                    <lib-button
                        [buttonSize]="'small'"
                        buttonText="Ok Got it!"
                        [disabled]="false"
                        (callback)="pageNavigation('okGotIt')"
                    ></lib-button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
