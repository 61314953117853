import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { LoadingComponent } from './loading/loading.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent, MatIconModule, PSICComponentsModule, LoadingComponent, NotificationCardComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    title = 'email-classification';

    constructor(private iconReg: MatIconRegistry, private sanitizer: DomSanitizer) {
        iconReg.addSvgIconResolver((name: string) =>
            sanitizer.bypassSecurityTrustResourceUrl(`/assets/images/${name}.svg`)
        );

        PSICComponentsModule.initIcons(this.sanitizer, this.iconReg);
    }
}
