import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoadingService } from './loading.service';
import { CUSTOM_CONFIG, CustomConfig } from '../custom.config';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class OktaAuthGuard {
    constructor(
        private oktaAuth: AuthService,
        private router: Router,
        private userService: UserService,
        private loadingService: LoadingService,
        @Inject(CUSTOM_CONFIG) private config: CustomConfig
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.loadingService.showLoading();
        if (!this.config.oktaLogin) {
            this.loadingService.hideLoading();
            return true;
        }

        let authenticated = false;
        try {
            authenticated = await this.oktaAuth.checkAuthenticated();
        } catch (ex) {
            console.error('Auth Error 4 - ' + JSON.stringify(ex));
        }

        let isSessionExists = false;
        try {
            isSessionExists = await this.oktaAuth.isSessionExists();
        } catch (ex) {
            console.error('Auth Error 5 - ' + JSON.stringify(ex));
        }

        if (isSessionExists) {
            let tokens = null;
            try {
                tokens = await this.oktaAuth.getTokens(true);
                this.oktaAuth.setTokens(tokens);
            } catch (ex) {
                console.error('Auth Error 6 - ' + JSON.stringify(ex));
                throw new HttpErrorResponse({ error: ex, status: 401, statusText: 'Unauthorized' });
            }

            if (authenticated || isSessionExists) {
                if (
                    !this.userService.getUser()['User_Name'] ||
                    this.userService.getUser()['User_Name'] != tokens?.idToken?.claims!.User_Name
                ) {
                    this.userService.setUser(tokens?.idToken?.claims!);
                }
                this.loadingService.hideLoading();
                return true;
            }
        }

        //TODO: We need to subscribe to logout event and redirect the user
        //to login page. Current okta sdk implementation is not working.
        //need to do more research on this.

        try {
            // Redirect to login flow.
            await this.handleLogin(state.url);
        } catch (ex) {
            console.error('Auth Error 7 - ' + JSON.stringify(ex));
        }
        return false;
    }

    private async handleLogin(fromUri: string): Promise<void> {
        // Store the current path
        this.oktaAuth.setOriginalUri(fromUri);
        this.loadingService.hideLoading();
        this.router.navigate(['/login']).catch((reason) => console.error('Auth Error 8 - ' + reason));
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}
