import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { CUSTOM_CONFIG, CustomConfig } from './app/custom.config';

fetch('/assets/config.json')
    .then((response) => response.json())
    .then((config: CustomConfig) => {
        if (environment.production) {
            enableProdMode();
        }
        addScripts(config.scripts);

        appConfig.providers.unshift({ provide: CUSTOM_CONFIG, useValue: config });
        bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
    });

function addScripts(scripts: string[]) {
    scripts.forEach((src) => {
        let s = document.createElement('script');
        s.setAttribute('src', src);
        document.head.appendChild(s);
    });
}
