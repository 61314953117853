import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  loadingSubscription = new Subscription();
  loadingTemplate!: TemplateRef<any>;

  @ViewChild('spinnerTemplate', { static: false }) spinnerTemplate!: TemplateRef<any>;

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {}

  ngOnDestroy() {
      this.loadingSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
      this.loadingService.setDefaultTemplate(this.spinnerTemplate);

      this.loadingSubscription.add(
          this.loadingService.getLoadingStatus().subscribe((value) => {
              this.loading = value;
          })
      );

      this.loadingSubscription.add(
          this.loadingService.loadingTemplate$.subscribe((template: any) => {
              this.loadingTemplate = template;
          })
      );
  }
}