import { Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { LoginComponent } from './login/login.component';
import { OktaAuthGuard } from './services/auth.guard.service';
import { ErrorSystemFailureComponent } from './error-system-failure/error-system-failure.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./task.routes').then((m) => m.TASK_CENTER_ROUTES),
        canActivate: [OktaAuthGuard],
    },
    {
        path: 'login/callback',
        component: OktaCallbackComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { state: 'login' },
    },
    {
        path: 'error-system-failure',
        component: ErrorSystemFailureComponent,
    },
];
