import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';

@Component({
    selector: 'app-error-system-failure',
    standalone: true,
    imports: [PSICComponentsModule],
    templateUrl: './error-system-failure.component.html',
    styleUrl: './error-system-failure.component.scss',
})
export class ErrorSystemFailureComponent {
    errorStatus: any;
    dataStateName: string = 'ErrorSystemFailure';
    //history go back

    constructor(private router: Router, private route: ActivatedRoute, private location: Location) {}

    ngOnInit(): void {
        this.errorStatus = this.route.snapshot.paramMap.get('errorStatus');
        if (this.errorStatus === 404) {
            this.dataStateName = 'ErrorEmptyState';
        }
    }

    pageNavigation($event: any): void {
        if ($event === 'okGotIt' || $event === 'goBack') {
            this.location.back();
        }
    }
}
