import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { CUSTOM_CONFIG, CustomConfig } from '../custom.config';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    token: any;
    response: any;
    private authClient = new OktaAuth({
        issuer: this.config.okta.issuer,
        clientId: this.config.okta.clientId,
        scopes: this.config.okta.scopes,
        pkce: this.config.okta.pkce,
        redirectUri: this.config.okta.redirectUri,
    });

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*',
        }),
        withCredentials: true,
    };

    public isAuthenticated = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        @Inject(CUSTOM_CONFIG) private config: CustomConfig,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
    ) {}

    // api call to get session id from okta
    getSessionId(): Observable<any> {
        const oktaUrl = this.config.okta.baseURL + this.config.getSessionURL;
        return this.http.get<any>(oktaUrl, this.httpOptions);
    }

    async checkAuthenticated() {
        const authenticated = await this.authClient.isAuthenticated();
        return authenticated;
    }

    async isSessionExists() {
        const sessionExists = await this.authClient.session.exists();
        return sessionExists;
    }

    async register(form: Object) {}

    async login(username: string, password: string) {
        const transaction = await this.authClient.signInWithCredentials({ username, password });

        if (transaction.status !== 'SUCCESS') {
            throw Error('We cannot handle the ' + transaction.status + ' status');
        }

        if (transaction.status === 'SUCCESS') {
            this.isAuthenticated.next(true);
            this.authClient.session.setCookieAndRedirect(transaction.sessionToken);
        }
    }

    async logout(redirect: string) {
        try {
            await this.authClient.signOut();
            this.isAuthenticated.next(false);
        } catch (err) {
            console.error(err);
        }
    }

    setOriginalUri(fromUri: string) {
        this.authClient.setOriginalUri(fromUri);
    }

    getUserDetails() {
        return this.authClient.getUser();
    }

    getAccessTokens() {
        return this.authClient.getAccessToken();
    }

    async getTokens(refresh = false) {
        this.token = this.authClient.getAccessToken()?.toString();
        if (this.tokenExpired(this.token) || refresh) {
            this.response = await this.authClient.token.getWithoutPrompt({
                responseType: 'token',
            });
        } else {
            return this.authClient.tokenManager.getTokens();
        }
        return this.response.tokens;
    }

    setTokens(tokens: Tokens) {
        this.authClient.tokenManager.setTokens(tokens);
    }

    private tokenExpired(token: string) {
        if (token) {
            const expiry = JSON.parse(atob(token.split('.')[1])).exp;
            let currdate = Math.floor(new Date().getTime() / 1000);
            return currdate >= expiry - 1800;
        }
        return true;
    }
}
