import { ApplicationConfig, ErrorHandler, Injector, inject } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { importProvidersFrom } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OKTA_CONFIG, OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { CUSTOM_CONFIG, CustomConfig } from './custom.config';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { ErrorService } from './services/error.service';
import { ModalModule } from 'ngx-bootstrap/modal';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        importProvidersFrom([BrowserAnimationsModule]),
        importProvidersFrom(HttpClientModule),
        importProvidersFrom(OktaAuthModule),
        importProvidersFrom(ModalModule.forRoot()),
        provideCharts(withDefaultRegisterables(DataLabelsPlugin)),
        { provide: OKTA_CONFIG, useFactory: resolveOktaConfig, deps: [CUSTOM_CONFIG] },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: ErrorService,
        },
    ],
};

export function resolveOktaConfig(config: CustomConfig): OktaConfig {
    const oktaAuth = new OktaAuth({
        issuer: config.okta.issuer,
        clientId: config.okta.clientId,
        redirectUri: `${config.appURL}/implicit/callback`,
        pkce: true,
        /* Proof Key for Code Exchange. Securely perform the OAuth exchange from public clients.
      Read more at https://oauth.net/2/pkce/ */
    });
    return {
        oktaAuth,
        onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            const router = injector.get(Router);
            // Redirect the user to your custom login page
            router.navigate(['/login']);
        },
    };
}
