import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Tokens } from '@okta/okta-auth-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CUSTOM_CONFIG, CustomConfig } from '../custom.config';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(private oktaAuth: AuthService, @Inject(CUSTOM_CONFIG) private config: CustomConfig) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.config.oktaLogin) {
            return next.handle(request);
        }
        return from(this.oktaAuth.getTokens()).pipe(
            switchMap((tokens: Tokens) => {
                if (request.url.includes(this.config.tasksAPI)) {
                    request = request.clone({
                        headers: request.headers.set('Authorization', `Bearer ${tokens.accessToken?.accessToken}`),
                    });
                }
                return next.handle(request);
            }),
            catchError((error) => {
                if (error?.errorCode == 'login_required') {
                    return next.handle(request);
                } else {
                    return throwError(error);
                }
            })
        );
    }
}
