import { Component, OnInit } from '@angular/core';
import { OktaWidgetComponent } from "../okta-widget/okta-widget.component";


@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [OktaWidgetComponent]
})
export class LoginComponent implements OnInit {
  constructor() { }
  ngOnInit() { }
}
