<div class="header wh-100-background w-100">
    <div class="d-flex flex-column">
        <div class="header-row d-flex flex-row align-items-center justify-content-between"
            [attr.dashboard]="isDashboard" [ngClass]="{
                'psic-py-1': !isMobile,
                'psic-px-7': isMobile,
                'psic-py-2': isMobile,
                'psic-gap-2': isMobile
            }">
            <!-- Mobile View -->
            <div *ngIf="isMobile" class="d-flex flex-row align-items-center justify-content-between w-100">
                <div class="d-flex flex-row align-items-center">
                    <!-- Hamburger menu icon -->
                    <div *ngIf="isLoggedIn && !isMobile" class="menu psic-p-2" (click)="toggleHamburgerMenu()">
                        <div class="hamburger-icon" [class.active]="!showNav"></div>
                        <div class="close-icon" [class.active]="showNav"></div>
                    </div>

                    <!-- PSIC logo section Mobile -->
                    <div class="logo-container d-flex align-items-center mx-auto">
                        <img src="../../assets/images/logo-mini.svg" alt="psic-logo-mini" />
                    </div>
                </div>

                <!-- section for url not containing "dashboard" text -->
                <div *ngIf="!isLoggedIn" class="customer-care-container d-flex flex-row align-items-center">
                    <!-- Customer Care icon -->
                    <div class="d-flex psic-p-2">
                        <mat-icon psicTooltipPlacement="bottom" psicTooltip="<div class='d-flex flex-column align-items-end'><p class='disclaimer'>Need help? Call us</p><p class='disclaimer-bold text-right'>
                                <strong><a href='#'>800 303 5000</a></strong></p></div>"
                            class="d-flex mat-icon-32 p1-50-color" [svgIcon]="'phone'"></mat-icon>
                    </div>
                </div>

                <!-- section for url containing "dashboard" text -->
                <div *ngIf="isLoggedIn" class="responsive-container d-flex align-items-center psic-gap-6">
                    <div class="d-flex flex-row psic-gap-6">
                        <!-- Mobile - Profile icon -->
                        <div style="min-width: max-content" class="user-avatar" (click)="toggleProfileNav()">
                            <mat-icon class="d-flex mat-icon-32 p1-50-color" [svgIcon]="'user-circle'"></mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Desktop View -->
            <div *ngIf="!isMobile"
                class="d-flex flex-row w-100 align-items-center justify-content-between psic-gap-9 psic-px-8 psic-px-4"
                style="height: 40px; width: 40px;">
                <div class="d-flex flex-row psic-gap-6">
                    <div *ngIf="isLoggedIn" class="menu" (click)="toggleHamburgerMenu()">
                        <div class="hamburger-icon" [class.active]="!showNav"></div>
                        <div class="close-icon" [class.active]="showNav"></div>
                    </div>

                    <!-- PSIC logo section Desktop -->
                    <div class="logo-container d-flex align-items-center" style="height: 33.84px; width: 198.03px;">
                        <ng-container *ngIf="isMobile || showHamburgerMenu; else default">
                            <img src="../../assets/images/psic_logo.svg" alt="psic-logo-img" />
                        </ng-container>
                        <ng-template #default>
                            <img src="../../assets/images/{{ logo }}.svg" alt="psic-logo-img" />
                        </ng-template>
                    </div>
                </div>

                <!-- Desktop - Need help section -->
                <div *ngIf="!isLoggedIn" class="customer-care-container">
                    <div class="d-flex flex-row psic-gap-8">
                        <!-- Customer Care icon -->
                        <div class="d-flex flex-row align-items-center psic-gap-2">
                            <div id="contact-text" class="d-flex flex-column align-items-center justify-center">
                                <p class="top">Need help? Call us</p>
                                <p class="bottom"><strong>800 303 5000</strong></p>
                            </div>
                            <mat-icon [svgIcon]="'phone'" class="d-flex mat-icon-24 dg-60-color"></mat-icon>
                        </div>
                    </div>
                </div>

                <!-- Desktop - Profile section -->
                <div *ngIf="isLoggedIn" class="user-avatar psic-gap-2 d-flex flex-row align-items-center"
                    (click)="toggleProfileNav()" style="cursor: pointer; min-width: max-content;">
                    <ng-container *ngIf="user$ | async as user">
                        <span *ngIf="!isMobile" class="d-none d-lg-flex caption-bold dg-60-color me-1">
                            {{ user['First_Name'] }} {{ user['Last_Name'] }}
                        </span>
                    </ng-container>

                    <!-- Avatar -->
                    <mat-icon class="d-flex mat-icon-32 p1-50-color" [svgIcon]="'user-circle'"></mat-icon>
                </div>

            </div>
            <!--End Desktop-->
        </div>
    </div>
</div>

<!-- Hamburger menu for Dashboard options on Dashboard-->
<div class="side-nav" [class.active]="showNav">
    <nav aria-label="side-nav-item" class="wh-100-background psic-pt-8 psic-p-6">
        <ul class="d-flex flex-column psic-p-0 psic-m-0 psic-gap-3">
            <li class="side-nav-item" routerLinkActive="active">
                <a routerLink="/dashboard" (click)="closeMenus()">
                    <img src="../../assets/images/clipboard-notes.svg" alt="policy-nav-img" />
                    Policy edit tasks
                </a>
            </li>
            <li class="side-nav-item" routerLinkActive="active">
                <a routerLink="/task-center" (click)="closeMenus()">
                    <img src="../../assets/images/Inspection.svg" alt="inspection-nav-img" />
                    Inspection report
                </a>
            </li>
            <li class="side-nav-item" routerLinkActive="active">
                <a routerLink="/dashboard" (click)="closeMenus()">
                    <img src="../../assets/images/shield-check-1.svg" alt="agency-nav-img" />
                    Agency Management System
                </a>
            </li>
            <li class="side-nav-item" routerLinkActive="active">
                <a routerLink="/task-center" (click)="closeMenus()">
                    <img src="../../assets/images/payments-qnb.svg" alt="quote-nav-img" />
                    Quote and bind
                </a>
            </li>
        </ul>
    </nav>
    <div class="backdrop" (click)="showNav = false; showProfileNav = false"></div>
</div>

<!-- Profile icon menu -->
<div class="profile-nav" [class.active]="showProfileNav">
    <nav aria-label="profile-nav-item" class="wh-100-background">
        <ul class="d-flex flex-column psic-p-0 psic-m-0">
            <li (click)="signOut()"
                class="side-nav-item caption d-flex flex-row align-items-center psic-gap-2 psic-py-4">
                <img src="../../assets/images/sign-out.svg" alt="sign-out" />
                Sign Out
            </li>
        </ul>
    </nav>
    <div class="backdrop" (click)="showProfileNav = false; showNav = false"></div>
</div>