import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
    errorThrown = new Subject<void>();

    constructor(private injector: Injector, private zone: NgZone, private authService: AuthService) {}

    handleError(error: HttpErrorResponse) {
        const router = this.injector.get(Router);
        this.errorThrown.next();

        switch (error.status) {
            case 0: //UNKNOWN
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            case 400: //BAD REQUEST
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            case 401: //UNAUTHORIZED
                this.zone.run(() => {
                    this.authService.logout('/login');
                });
                break;
            case 403: //FORBIDDEN
                this.zone.run(() => {
                    this.authService.logout('/login');
                });
                break;
            case 404: //NOT FOUND
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            case 500: //INTERNAL SERVER ERROR
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            case 503: //SERVICE UNAVAILABLE
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            case 504: //TIMEOUT
                this.zone.run(() => {
                    router.navigate(['/error-system-failure']);
                });
                break;
            default:
                console.error(error);
        }
    }
}
