import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from '../services/user.service';
import { UserClaims } from '@okta/okta-auth-js';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [PSICComponentsModule, MatIconModule, RouterLink, CommonModule],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})

export class HeaderComponent implements OnInit, OnDestroy {
    isDashboard: boolean = true;
    showNav: boolean = false;
    isLoggedIn: boolean = false;
    isMobile: boolean = false;
    logo = 'psic_logo';
    showHamburgerMenu: boolean = false;
    showProfileNav: boolean = false;
    userSubscription: Subscription | undefined;
    authListSubscription: Subscription | undefined;
    authList$: Observable<string[]> | undefined;
    user$: Observable<UserClaims> | undefined;
    private routerSubscription: Subscription | undefined;
    private breakpointSubscription: Subscription | undefined;
    private oktaAuth = inject(OKTA_AUTH);

    constructor(private router: Router,
        private breakpointObserver: BreakpointObserver,
        public userService: UserService,
        private authService: AuthService
    ) { }

    async ngOnInit(): Promise<void> {
        this.updateLoginStatus();
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.updateLoginStatus();
            this.isDashboard = this.router.url.includes('/dashboard');
        });

        this.isMobile = this.breakpointObserver.isMatched('(max-width: 768px)');
        this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result) => {
            this.isMobile = result.matches;
        });

        this.user$ = this.userService.user$;
        this.user$.subscribe(user => {
            this.isLoggedIn = !!user.sub;
        });

    }

    ngOnDestroy(): void {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        if (this.breakpointSubscription) {
            this.breakpointSubscription.unsubscribe();
        }
        this.userSubscription?.unsubscribe();
    }

    private async updateLoginStatus(): Promise<void> {
        const isLoginRoute = this.router.url === '/login';
        this.isLoggedIn = !isLoginRoute && await this.oktaAuth.isAuthenticated();
    }

    async signOut(): Promise<void> {
        await this.authService.logout('/login');
    }

    toggleHamburgerMenu(): void {
        this.showNav = !this.showNav;
        this.showProfileNav = false;
    }

    toggleProfileNav(): void {
        this.showProfileNav = !this.showProfileNav;
        this.showNav = false;
    }

    closeMenus(): void {
        this.showNav = false;
        this.showHamburgerMenu = false;
    }
}
