import { Injectable } from '@angular/core';
import { IDToken, UserClaims } from '@okta/okta-auth-js';
import { BehaviorSubject } from 'rxjs';
import app from '../../config/app';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly _user: BehaviorSubject<UserClaims> = new BehaviorSubject({ sub: '' });
    readonly user$ = this._user.asObservable();

    constructor() {}

    setUser(user: UserClaims) {
        this._user.next(user);
    }

    getUser() {
        return this._user.getValue();
    }

    isCustomer() {
        return app.customerUser.includes(this._user.getValue()['Producer_Code'] as string);
    }

    isInternalUser(): boolean {
        return app.internalUser.includes(this._user.getValue()['Producer_Code'] as string);
    }
}
