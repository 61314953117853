import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { Tokens, OktaAuth } from '@okta/okta-auth-js';
import OktaSignIn from '@okta/okta-signin-widget';
import { PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { CUSTOM_CONFIG, CustomConfig } from '../custom.config';


@Component({
  selector: 'app-okta-widget',
  standalone: true,
  imports: [PSICComponentsModule],
  templateUrl: './okta-widget.component.html',
  styleUrl: './okta-widget.component.scss'
})


export class OktaWidgetComponent implements OnInit {
  authService;
  isMobileWidth: boolean = false;

  signInWidgetConfig = {
    language: 'en',
    i18n: {
      en: {
        'primaryauth.title': 'Sign In',
        'primaryauth.submit': 'Sign In',
        'primaryauth.password.placeholder': 'Password',
        'primaryauth.username.tooltip': 'Enter user name.',
        'primaryauth.username.placeholder': 'Sign In',
        'password.forgot.email': 'Email or Username.',
        'username.placeholder': 'Enter Email or Username.',
      },
    },
    features: {
      registration: false,
      rememberMe: false,
      selfServiceUnlock: true,
      smsRecovery: true,
      passwordlessAuth: true,
    },

    baseUrl: this.config.okta.baseURL,
    authParams: {
      pkce: true,
      issuer: this.config.okta.issuer,
      scopes: this.config.okta.scopes,
    },
    clientId: this.config.okta.clientId,
    redirectUri: this.config.okta.redirectUri,
    el: '#widget-container',
  };
  signIn = new OktaSignIn(this.signInWidgetConfig);

  constructor(
    @Inject(OKTA_AUTH) oktaAuth: OktaAuth,
    @Inject(CUSTOM_CONFIG) private config: CustomConfig,
    private router: Router) {
    this.authService = oktaAuth;

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        switch (event.url) {
          case '/login':
            break;
          case '/protected':
            break;
          default:
            this.signIn.remove();
            break;
        }
      }
    });
  }

  async ngOnInit() {
    const originalUri = this.authService.getOriginalUri();
    if (!originalUri) {
      this.authService.setOriginalUri('/');
    }

    const tokens: Tokens = await this.signIn.showSignInToGetTokens({
      el: '#widget-container',
    });
    this.authService.handleLoginRedirect(tokens);
    this.signIn.hide();
    let isAuthenticated = await this.authService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigate(['/dashboard']);
    }
  }


  @HostListener('window:resize', ['$event'])
  getWidthOfScreenSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 576) {
      this.isMobileWidth = true;
    } else {
      this.isMobileWidth = false;
    }
  }
}