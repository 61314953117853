<div
    *ngFor="let toast of toastService.toasts; index as i"
    class="toast d-flex flex-column align-content-center"
    [ngClass]="toast.type == 'error' ? 'error' : ''"
    style="position: fixed" [ngStyle]="{top : positionTop}"
>
    <div class="d-flex flex-row">
        <div class="d-flex flex-row" [ngClass]="toast.type == 'error' ? 'align-items-start' : 'align-items-center'" 
            style="width: 100%;">
            <div class="flex-column">
                <img
                    *ngIf="toast.type == 'error'"
                    src="../../../../assets/images/notification-icon-error.svg"
                    alt="notification-icon-img"
                />
                <img
                    *ngIf="toast.type != 'error'"
                    src="../../../../assets/images/notification-icon.svg"
                    alt="notification-icon-img"
                />
            </div>
            <div class="flex-column flex-fill">
                <div class="d-inline-flex text-break caption wh-100-color ps-2">{{ toast.message }}</div>
            </div>
            <div class="flex-column">
                <img src="../../../../assets/images/close-icon.svg" (click)="remove(i)" alt="close-icon-img" />
            </div>
        </div>
        
    </div>
</div>
